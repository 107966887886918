<template>
  <div class="placeholder-auth">
    <div
      class="auth-green"
      :class="{ isPayment: this.$store.getters.isPayment }"
    >
      <h1>
        <p>Ihr Tool für</p>
        <p>die bessere</p>
        <p>Windkraftanalyse.</p>
      </h1>
      <div v-for="i in 30" :key="i" :class="`dotWrapper dotWrapper-${i}`">
        <div :class="`dot dot-${i}`"></div>
      </div>
    </div>
    <login-view v-if="!this.$store.getters.isPayment"></login-view>
    <subscription v-if="this.$store.getters.isPayment"></subscription>
  </div>
</template>

<script>
import Subscription from "@/components/Auth/Subscription.vue";
import LoginView from "@/components/Auth/LoginView.vue";
export default {
  components: { Subscription, LoginView },
  name: "Auth",
  setup() {},
};
</script>
