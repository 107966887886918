<template>
  <div class="main-subscription">
    <fieldset>
      <legend>Plan:</legend>
      <p>Your plan will be 99,99€ per month.</p>
    </fieldset>
    <fieldset>
      <legend>Info:</legend>
      <div class="input4">
        <div class="centeredSpaceSection">
          <p class="text text--label">Unternehmens-Name:</p>
          <input
            class="textInputBig"
            name="uName"
            id="uName"
            type="text"
            placeholder="AlphaOmegaGreen"
            v-model="uName"
          />
          <p class="text text--label">Umsatzsteuer-ID:</p>
          <input
            class="textInputBig"
            name="uID"
            id="uID"
            type="text"
            placeholder="DE123456789"
            v-model="uID"
          />
          <div class="checkboxes">
            <input
              type="checkbox"
              id="checkboxTerms"
              name="checkboxTerms"
              value=""
            />
            <label for="checkboxTerms">
              Check here to indicate that you have read and agree to the
              <a
                href="javascript:void(0)"
                class="firebaseui-link firebaseui-tos-link"
                target="_blank"
                data-v-b8e47f78=""
              >
                Terms of Service
              </a>
            </label>
            <p></p>
            <input
              type="checkbox"
              id="checkboxPrivacy"
              name="checkboxPrivacy"
              value=""
            />
            <label for="checkboxPrivacy">
              Check here to indicate that you have read and agree to the
              <a
                href="javascript:void(0)"
                class="firebaseui-link firebaseui-tos-link"
                target="_blank"
                data-v-b8e47f78=""
              >
                Privacy Policy
              </a>
            </label>
          </div>
        </div>
      </div>
    </fieldset>

    <button class="subscription-button" @click="subscribe">Abonnieren</button>
  </div>
  <p class="subscription-logout" @click="logOut">Log Out</p>
</template>

<script>
import firebase from "firebase";
import { ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
const STRIPE_PUBLISHABLE_KEY =
  "pk_test_51K3znYEpXymqZg1CnB7oHLNC25t4Eq3uitmtxAunDUCVcDx0ybQoURKAZOdK0SIpnkejUiD8DV3HF1FnnuorPufD00TEWXYhyj";
const ServerURL = process.env.VUE_APP_ENV_SERVER || "";
import axios from "axios";

export default {
  name: "Subscription",
  setup() {
    const db = firebase.firestore();
    const description = ref("");
    const store = useStore();
    const uName = ref(null);
    const uID = ref(null);
    async function subscribe() {
      if (
        document.getElementById("checkboxTerms").checked &&
        document.getElementById("checkboxPrivacy").checked
      ) {
        store.dispatch("setShowLoadingScreen", true);
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(function (idToken) {
            db.collection("customers")
              .doc(firebase.auth().currentUser.uid)
              .get()
              .then(async (snapshot) => {
                const stripeUserID = await snapshot.data().stripeId;
                axios
                  .post(ServerURL + "/setUserData", {
                    stripeID: stripeUserID,
                    uName: uName.value,
                    uID: uID.value,
                    idToken: idToken,
                  })
                  .then((response) => response.data)
                  .then(async (setDataSuccess) => {
                    if (setDataSuccess.done) {
                      const checkoutSession = {
                        collect_shipping_address: true,
                        tax_rates: ["txr_1KE37iEpXymqZg1CfPSqVsVQ"],
                        line_items: [
                          {
                            price: "price_1KE3BsEpXymqZg1C0OaA3ycg",
                            quantity: 1,
                          },
                        ],
                        success_url: window.location.origin,
                        cancel_url: window.location.origin,
                        allow_promotion_codes: true,
                        metadata: {
                          key: "value",
                        },
                      };
                      const docRef = await db
                        .collection("customers")
                        .doc(firebase.auth().currentUser.uid)
                        .collection("checkout_sessions")
                        .add(checkoutSession);
                      docRef.onSnapshot((snap) => {
                        const { error, sessionId } = snap.data();
                        if (error) {
                          // Show an error to your customer and then inspect your function logs.
                          alert(`An error occured: ${error.message}`);
                          document
                            .querySelectorAll("button")
                            .forEach((b) => (b.disabled = false));
                        }
                        if (sessionId) {
                          const stripe = window.Stripe(STRIPE_PUBLISHABLE_KEY);
                          stripe.redirectToCheckout({ sessionId });
                        }
                      });
                    } else {
                      console.log(setDataSuccess);
                      store.dispatch("setShowLoadingScreen", false);

                      if (setDataSuccess.error.code == "tax_id_invalid") {
                        Swal.fire("Error", "The tax id is invalid");
                      } else {
                        Swal.fire(
                          "Error",
                          "Unexpected Error with updating User"
                        );
                      }
                    }
                  });
              })
              .catch(function (error) {
                console.log("hier");
                console.log(error);
                Swal.fire("Error", "Unexpected Error with updating User");
              });
          });
      } else if (
        document.getElementById("checkboxTerms").checked &&
        !document.getElementById("checkboxPrivacy").checked
      ) {
        Swal.fire({
          title: "Error!",
          text: "Please agree to our Privacy Policy!",
          icon: "error",
          confirmButtonText: "Okay",
        });
      } else if (
        !document.getElementById("checkboxTerms").checked &&
        document.getElementById("checkboxPrivacy").checked
      ) {
        Swal.fire({
          title: "Error!",
          text: "Please agree to our Terms of Service!",
          icon: "error",
          confirmButtonText: "Okay",
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "Please agree to our Terms of Service and our Privacy Policy!",
          icon: "error",
          confirmButtonText: "Okay",
        });
      }
    }
    function logOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          store.dispatch("setIsPayment", false);
        });
    }
    return { description, subscribe, logOut, uName, uID };
  },
};
</script>

<style scoped lang="scss">
.textInputBig {
  padding: 5px 5px;
  border: 0.5px solid;
  height: 5vh;
  width: 80%;
}
.input4 {
  align-self: center;
}

.checkboxes {
  align-self: baseline;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

fieldset {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 60px;
  border: 1px solid var(--AOG-light-green);
}

.subscription-button {
  margin-top: -20px;
}
</style>
