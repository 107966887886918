<template>
  <div class="firebaseui-container">
    <h1>Anmelden / Registrieren</h1>
    <section id="firebaseui-auth-container" ref="firebaseUIRef"></section>
  </div>
</template>

<script>
import firebase from "firebase";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { onMounted, ref } from "vue";

export default {
  name: "LoginView",
  setup() {
    const firebaseUIRef = ref(null);
    const firebaseUI =
      window.firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth());
    const uiConfig = {
      signInFlow: "popup",
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,

      // Your terms of service url.
      tosUrl: "https://example.com/terms",
      // Your privacy policy url.
      privacyPolicyUrl: "https://example.com/privacy",
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
    };
    onMounted(() => {
      firebaseUI.reset();
      firebaseUIRef.value.innerHTML = "";
      firebaseUI.start("#firebaseui-auth-container", uiConfig);
    });
    return {
      firebaseUIRef,
    };
  },
};
</script>

<style></style>
